<template>
    <div class="program">
        <div class="program-content-wrapper">
          <div class="program-content">
            <div>
              <a href="/Programm-Wintersemester-2024.pdf" download="Salia-Silesia-WS-2024-25">
                <img class="brand-logo" alt="salia-wappen" src="../assets/navbarImages/salia-wappen.png"/>
              </a>
              <h2>
                {{ $t('program.semestralProgram') }}
              </h2>
              <p class="program-data">
                {{ $t('program.downloadProgramInfo') }}              
              </p>
            </div>
          </div>
          <img class="band-navbar" src="../assets/navbarImages/band.jpg"/>                     
        </div>
        <div class="calendar">         
          <v-calendar
            is-expanded
            :columns="$screens({ default: 1, md: 2, lg: 2, xl: 3 })"
            :rows="$screens({ default: 2, xl: 2 })"
            :attributes='attributes'
            :locale="$t('calendar.language')"
          />
        </div>
        <div class="pdf-viewer">
          <PdfViewer></PdfViewer>
        </div> 
    </div>
</template>

<script>
import PdfViewer from '../components/PdfViewer';

export default {
  name: "Program",
  components: {
      PdfViewer,
  },
  data() {
    return {
    };  
  },
  computed: {
    attributes() {
      return [
        {
          key: 'today',
          highlight: {
            color: 'yellow',
            fillMode: 'outline',
          },
          dates: new Date(),
          popover: {
            label: 'Heute'
          }
        },
        {
          key: 'semesterantritt-ws-2021',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2021, 9, 2),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'semesterantritt-ws-2021-popup-1',
          dot: 'gray',
          dates: new Date(2021, 9, 2),
          popover: {
            label: '19:00 hst ho. 1. Convent AC/BC'
          }
        },
        {
          key: 'semesterantritt-ws-2021-popup-2',
          dot: 'gray',
          dates: new Date(2021, 9, 2),
          popover: {
            label: '20:00 hct ho. Semesterantrittskneipe'
          }
        },
        {
          key: 'schiessen-2021',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2021, 9, 16),
          popover: {
            label: 'Ort: Chronstau (Chrząstowice)'
          }
        },
        {
          key: 'schiessen-2021-popup-1',
          dot: 'gray',
          dates: new Date(2021, 9, 16),
          popover: {
            label: '19:00 hst io. Schießübungen am Schießstand und anschließender Stammtisch'
          }
        },
        {
          key: 'stammtisch-2021',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2021, 9, 29),
          popover: {
            label: 'Ort: Oppeln (genauere Ort wird angegeben)'
          }
        },
        {
          key: 'stammtisch-2021-popup-1',
          dot: 'gray',
          dates: new Date(2021, 9, 29),
          popover: {
            label: '19:00 hct io. Stammtisch'
          }
        },
        {
          key: 'guido-2021',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2021, 10, 6),
          popover: {
            label: 'Ort: Hindenburg OS'
          }
        },
        {
          key: 'guido-2021-popup-1',
          dot: 'gray',
          dates: new Date(2021, 10, 6),
          popover: {
            label: 'io. Besichtigung des Bergwerks (Kopalnia Guido)'
          }
        },
        {
          key: 'convent-2-2021',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2021, 10, 20),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'convent-2-2021-popup-1',
          dot: 'gray',
          dates: new Date(2021, 10, 20),
          popover: {
            label: '19:00 hst ho. 2. Convent AC/BC'
          }
        },                                                  
        {
          key: 'schweinschlachten-2021',
          highlight: {
            start: { fillMode: 'solid', color: 'red' },
            base: { fillMode: 'light', color: 'red' },
            end: { fillMode: 'solid', color: 'red' },
          },
          dates: {
            start: new Date(2021, 10, 26),
            end: new Date(2021, 10, 27),
          },
          popover: {
            label: 'Das alljährliche Schweinschlachten in Körnitz (Kórnica)'
          }
        },
        {
          key: 'schweinschlachten-2021-popup-1',
          dot: 'gray',
          dates: new Date(2021, 10, 26),
          popover: {
            label: '19:00 hct io. Begrüßungsabend adH'
          }
        },
        {
          key: 'schweinschlachten-2021-popup-2',
          dot: 'gray',
          dates: new Date(2021, 10, 27),
          popover: {
            label: '11:00 hct io. Eventschlachten bei Familie Rybczyk'
          },
        },
        {
          key: 'schweinschlachten-2021-popup-3',
          dot: 'gray',
          dates: new Date(2021, 10, 27),
          popover: {
            label: '16:30 hct io. Cumulativ Convent'
          },
        },
        {
          key: 'schweinschlachten-2021-popup-4',
          dot: 'gray',
          dates: new Date(2021, 10, 27),
          popover: {
            label: '20:30 hct io. Feierliche Andreaskneipe'
          },
        },
        {
          key: 'pokerabend-2021',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2021, 11, 3),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'pokerabend-2021-popup-1',
          dot: 'gray',
          dates: new Date(2021, 11, 3),
          popover: {
            label: '19:30 hct io. Pokerabend/Brettspielabend'
          }
        },
        {
          key: 'steakabend-2021',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2021, 11, 11),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'steakabend-2021-popup-1',
          dot: 'gray',
          dates: new Date(2021, 11, 11),
          popover: {
            label: '16:00 hct o. Steakabend'
          }
        },
        {
          key: 'lanparty-2021',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2021, 11, 18),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'lanparty-2021-popup-1',
          dot: 'gray',
          dates: new Date(2021, 11, 18),
          popover: {
            label: '16:00 hct o. LAN Party'
          }
        },
        {
          key: 'weihnachtsfeier-2021',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2021, 11, 27),
          popover: {
            label: 'Ort: Groß Stein'
          }
        },
        {
          key: 'weihnachtsfeier-2021-popup-1',
          dot: 'gray',
          dates: new Date(2021, 11, 27),
          popover: {
            label: '19:00 hct o. Weihnachtsfeier bei Bbr. Arnold Nowak'
          }
        },
        {
          key: 'semesterabschluss-ws-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 0, 14),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'semesterabschluss-ws-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 0, 14),
          popover: {
            label: '18:00 hct ho. 3. Convent AC/BC/DC/WC'
          }
        },
        {
          key: 'semesterabschluss-ws-2022-popup-2',
          dot: 'gray',
          dates: new Date(2022, 0, 14),
          popover: {
            label: '20:00 hct ho. Semesterabschlusskneipe'
          }
        },       
        {
          key: 'semesterantritt-ss-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 2, 5),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'semesterantritt-ss-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 2, 5),
          popover: {
            label: '19:00 hst ho. 1. Convent AC/BC'
          }
        },
        {
          key: 'semesterantritt-ss-2022-popup-2',
          dot: 'gray',
          dates: new Date(2022, 2, 5),
          popover: {
            label: '20:00 hct ho. Semesterantrittskneipe'
          }
        },
        {
          key: 'stammtisch-tartufo-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 2, 9),
          popover: {
            label: 'Ort: Oppeln'
          }
        },
        {
          key: 'stammtisch-tartufo-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 2, 9),
          popover: {
            label: '19:00 hct o. Stammtisch im Gasthaus Brasseria Tartufo'
          }
        },
        {
          key: 'biertour-breslau-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 2, 19),
          popover: {
            label: 'Ort: Breslau'
          }
        },
        {
          key: 'biertour-breslau-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 2, 19),
          popover: {
            label: 'io. Biertour'
          }
        },
        {
          key: 'besichtigung-nikischau-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 2, 26),
          popover: {
            label: 'Ort: Nikischau'
          }
        },
        {
          key: 'besichtigung-nikischau-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 2, 26),
          popover: {
            label: '10:00 hct o. Besichtigung der historischen Arbeitersiedlung Nikischacht'
          }
        },
        {
          key: 'sauna-abend-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 3, 2),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'sauna-abend-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 3, 2),
          popover: {
            label: 'io. Sauna Abend'
          }
        },
        {
          key: 'osterstammtisch-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 3, 19),
          popover: {
            label: 'Ort: Oberglogau'
          }
        },
        {
          key: 'osterstammtisch-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 3, 19),
          popover: {
            label: '19:00 hct o. Traditioneller Osterstammtisch im Gasthaus SALVE'
          }
        },
        {
          key: 'besichtigung-bergwerkmuseum-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 3, 30),
          popover: {
            label: 'Ort: Hindenburg OS'
          }
        },
        {
          key: 'besichtigung-bergwerkmuseum-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 3, 30),
          popover: {
            label: 'io. Besichtigung des Bergwerksmuseums'
          }
        },
        {
          key: 'convent-2-ss-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 4, 7),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'convent-2-ss-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 4, 7),
          popover: {
            label: '19:00 hst ho. 2. Convent AC/BC'
          }
        },
        {
          key: 'paintball-ss-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 4, 14),
          popover: {
            label: 'Ort: Krascheow'
          }
        },
        {
          key: 'paintball-ss-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 4, 14),
          popover: {
            label: 'io. Paintball bei "Bunker Paintball"'
          }
        },
        {
          key: 'convent-3-ss-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 4, 28),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'convent-3-ss-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 4, 28),
          popover: {
            label: '16:00 hct o. Hausputztag'
          }
        },
        {
          key: 'convent-3-ss-2022-popup-2',
          dot: 'gray',
          dates: new Date(2022, 4, 28),
          popover: {
            label: '19:00 hst ho. 3. Convent AC/BC'
          }
        },
        {
          key: 'motocross-ss-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 5, 11),
          popover: {
            label: 'Ort: Hindenburg OS'
          }
        },
        {
          key: 'motocross-ss-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 5, 11),
          popover: {
            label: 'io. Motocross fahren'
          }
        },
        {
          key: 'semesterabschluss-ss-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 5, 25),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'semesterabschluss-ss-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 5, 25),
          popover: {
            label: '18:00 hst ho. 4. Convent AC/BC/DC/WC'
          }
        },
        {
          key: 'semesterabschluss-ss-2022-popup-2',
          dot: 'gray',
          dates: new Date(2022, 5, 25),
          popover: {
            label: '20:00 hct ho. Semesterabschlusskneipe'
          }
        },
        {
          key: 'minderheiten-wallfahrt-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 6, 10),
          popover: {
            label: 'Ort: Wartha'
          }
        },
        {
          key: 'minderheiten-wallfahrt-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 6, 10),
          popover: {
            label: 'io. Minderheiten Wallfahrt'
          }
        },
        {
          key: 'stiftungsfest-2022',
          highlight: {
            start: { fillMode: 'solid', color: 'red' },
            base: { fillMode: 'light', color: 'red' },
            end: { fillMode: 'solid', color: 'red' },
          },
          dates: {
            start: new Date(2022, 5, 2),
            end: new Date(2022, 5, 5),
          },
          popover: {
            label: '30. Stiftungsfest im Hotel Arkas in Proskau (Prószków)'
          }
        },
        {
          key: 'stiftungsfest-2022-day-2',
          highlight: {
            color: 'red',
            fillMode: 'solid',
          },
          dates: new Date(2022, 5, 3),
        },
        {
          key: 'stiftungsfest-2022-day-3',
          highlight: {
            color: 'red',
            fillMode: 'solid',
          },
          dates: new Date(2022, 5, 4),
        },
        {
          key: 'stiftungsfest-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 5, 2),
          popover: {
            label: '19:00 hct o. Begrüßungsabend adH'
          }
        },
        {
          key: 'stiftungsfest-2022-popup-2',
          dot: 'gray',
          dates: new Date(2022, 5, 3),
          popover: {
            label: '19:30 hct o. Festball'
          }
        },
        {
          key: 'stiftungsfest-2022-popup-3',
          dot: 'gray',
          dates: new Date(2022, 5, 4),
          popover: {
            label: '10:30 hct io. Damenprogramm - Besichtigung des Silberbergwerk in Tarnowitz'
          }
        },
        {
          key: 'stiftungsfest-2022-popup-4',
          dot: 'gray',
          dates: new Date(2022, 5, 4),
          popover: {
            label: '12:00 hst ho. Sitzung vom Studentenförderverein Silesia e.V.'
          }
        },
        {
          key: 'stiftungsfest-2022-popup-5',
          dot: 'gray',
          dates: new Date(2022, 5, 4),
          popover: {
            label: '14:00 hct io. Mittagessen'
          }
        },
        {
          key: 'stiftungsfest-2022-popup-6',
          dot: 'gray',
          dates: new Date(2022, 5, 4),
          popover: {
            label: '15:00 hst ho. AHC/CC'
          }
        },
        {
          key: 'stiftungsfest-2022-popup-7',
          dot: 'gray',
          dates: new Date(2022, 5, 4),
          popover: {
            label: '19:00 hct io. Abendessen'
          }
        },
        {
          key: 'stiftungsfest-2022-popup-8',
          dot: 'gray',
          dates: new Date(2022, 5, 4),
          popover: {
            label: '20:00 hst ho. Festkommers'
          }
        },
        {
          key: 'stiftungsfest-2022-popup-9',
          dot: 'gray',
          dates: new Date(2022, 5, 5),
          popover: {
            label: '11:00 hst ho. Minderheiten Wallfahrt am Sankt Annaberg'
          }
        },
        {
          key: 'merk-2022',
          highlight: {
            start: { fillMode: 'solid', color: 'red' },
            base: { fillMode: 'light', color: 'red' },
            end: { fillMode: 'solid', color: 'red' },
          },
          dates: {
            start: new Date(2022, 8, 30),
            end: new Date(2022, 9, 2),
          },
          popover: {
            label: 'MERK und 22. Stiftungsfest e.v. KStV Pragensis Prag in Jermer (Jaromer)'
          }
        },
        {
          key: 'merk-2022-day-2',
          highlight: {
            color: 'red',
            fillMode: 'solid',
          },
          dates: new Date(2022, 9, 1),
        },
        {
          key: 'merk-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 8, 30),
          popover: {
            label: '18:00 hct o. Begrüßungsabend in Restaurace U Otty, Svatopluka Cecha 284, 551 01 Jaroměř'
          }
        },
        {
          key: 'merk-2022-popup-2',
          dot: 'gray',
          dates: new Date(2022, 9, 1),
          popover: {
            label: '10:00 hct o. Treffpunkt bei Mariensäule auf dem Hauptplatz'
          }
        },
        {
          key: 'merk-2022-popup-3',
          dot: 'gray',
          dates: new Date(2022, 9, 1),
          popover: {
            label: '11:00 hct o. Besichtigung der Festung Josefstadt/Pevnost Josefov'
          }
        },
        {
          key: 'merk-2022-popup-4',
          dot: 'gray',
          dates: new Date(2022, 9, 1),
          popover: {
            label: '12:30 hct o. Mittagessen in Restaurace Beseda, Lidicka ulice 6, Josefov'
          }
        },
        {
          key: 'merk-2022-popup-5',
          dot: 'gray',
          dates: new Date(2022, 9, 1),
          popover: {
            label: '18:00 hct o. Abendessen in Restaurace Corrado Na Obci 168, Jaromer'
          }
        },
        {
          key: 'merk-2022-popup-6',
          dot: 'gray',
          dates: new Date(2022, 9, 1),
          popover: {
            label: '19:00 hct ho. Feierliche Ringkneipe'
          }
        },
        {
          key: 'merk-2022-popup-7',
          dot: 'gray',
          dates: new Date(2022, 9, 2),
          popover: {
            label: '8:30 hct o. Heilige Messe in der Nikolauskirche nam. Ceskoslovenske armady 2, Jaromer'
          }
        },
        {
          key: 'merk-2022-popup-8',
          dot: 'gray',
          dates: new Date(2022, 9, 2),
          popover: {
            label: '9:30 hct o. Ausklang beim gemeinsamen Essen und Exbummel'
          }
        },
        {
          key: 'schweinschlachten-2022',
          highlight: {
            start: { fillMode: 'solid', color: 'red' },
            base: { fillMode: 'light', color: 'red' },
            end: { fillMode: 'solid', color: 'red' },
          },
          dates: {
            start: new Date(2022, 10, 25),
            end: new Date(2022, 10, 26),
          },
          popover: {
            label: 'Das alljährliche Schweinschlachten in Körnitz (Kórnica)'
          }
        },
        {
          key: 'schweinschlachten-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 10, 25),
          popover: {
            label: '19:00 hct io. Begrüßungsabend adH'
          }
        },
        {
          key: 'schweinschlachten-2022-popup-2',
          dot: 'gray',
          dates: new Date(2022, 10, 26),
          popover: {
            label: '11:00 hct io. Eventschlachten bei Familie Rybczyk'
          },
        },
        {
          key: 'schweinschlachten-2022-popup-3',
          dot: 'gray',
          dates: new Date(2022, 10, 26),
          popover: {
            label: '16:30 hct ho. Cumulativ Convent'
          },
        },
        {
          key: 'schweinschlachten-2022-popup-4',
          dot: 'gray',
          dates: new Date(2022, 10, 26),
          popover: {
            label: '20:30 hct ho. Feierliche Andreaskneipe'
          },
        },
        {
          key: 'semesterantritt-ws-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 9, 8),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'semesterantritt-ws-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 9, 8),
          popover: {
            label: '18:00 hst ho. 1. Convent AC/BC/DC'
          }
        },
        {
          key: 'semesterantritt-ws-2022-popup-2',
          dot: 'gray',
          dates: new Date(2022, 9, 8),
          popover: {
            label: '20:00 hct ho. Semesterantrittskneipe'
          }
        },
        {
          key: 'hissen-fahne-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 9, 9),
          popover: {
            label: 'Ort: Oppeln'
          }
        },
        {
          key: 'hissen-fahne-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 9, 9),
          popover: {
            label: '10:00 hst ho. Heilige Messe in St. Aleksy Kirche'
          }
        },
        {
          key: 'hissen-fahne-2022-popup-2',
          dot: 'gray',
          dates: new Date(2022, 9, 9),
          popover: {
            label: '12:00 hct ho. Hissen der Fahne adH'
          }
        },
        {
          key: 'deutschstammtisch-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 9, 18),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'deutschstammtisch-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 9, 18),
          popover: {
            label: '17:00 hct io. Deutschstammtisch mit DFK Oppeln-Zentrum'
          }
        },
        {
          key: 'besichtigung-ratibor-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 9, 22),
          popover: {
            label: 'Ort: Ratibor'
          }
        },
        {
          key: 'besichtigung-ratibor-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 9, 22),
          popover: {
            label: '19:00 hct io. Besichtigung der Stadt Ratibor und der Ratiborer Brauerei'
          }
        },
        {
          key: 'filmvorfuhrung-ulitzka-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 9, 27),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'filmvorfuhrung-ulitzka-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 9, 27),
          popover: {
            label: '18:00 hct io. Vorführung des Films "Ulitzka"'
          }
        },
        {
          key: 'filmvorfuhrung-ulitzka-2022-popup-2',
          dot: 'gray',
          dates: new Date(2022, 9, 27),
          popover: {
            label: 'Organisiert von unserem Gründungsbursch Bbr Christoph Wysdak'
          }
        },
        {
          key: 'schiessstand-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 10, 5),
          popover: {
            label: 'Ort: Chronstau'
          }
        },
        {
          key: 'schiessstand-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 10, 5),
          popover: {
            label: '19:00 hct io. Schießübungen am Schießstand und anschließender Stammtisch adH'
          }
        },
        {
          key: 'gentlemens-night-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 10, 12),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'gentlemens-night-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 10, 12),
          popover: {
            label: '19:00 hct o. Gentlemen\'s night'
          }
        },
        {
          key: 'weihnachtsmarkt-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 10, 18),
          popover: {
            label: 'Ort: Breslau'
          }
        },
        {
          key: 'weihnachtsmarkt-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 10, 18),
          popover: {
            label: '20:00 hct o. Eröffnung des Breslauer Weihnachtsmarktes'
          }
        },
        {
          key: 'hausputztag-2-convent-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 10, 19),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'hausputztag-2-convent-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 10, 19),
          popover: {
            label: '12:00 hct io. Hausputztag'
          }
        },
        {
          key: 'hausputztag-2-convent-2022-popup-2',
          dot: 'gray',
          dates: new Date(2022, 10, 19),
          popover: {
            label: '18:00 hst ho. 2. Convent AC/BC'
          }
        },
        {
          key: 'spirituosen-verkostung-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 11, 10),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'spirituosen-verkostung-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 11, 10),
          popover: {
            label: 'io. Spirituosen Verkostung'
          }
        },
        {
          key: 'lan-party-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 11, 17),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'lan-party-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 11, 17),
          popover: {
            label: '19:00 hct io. LAN-Party'
          }
        },
        {
          key: 'weihnachtsfeier-2022',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2022, 11, 27),
          popover: {
            label: 'Ort: Groß Stein'
          }
        },
        {
          key: 'weihnachtsfeier-2022-popup-1',
          dot: 'gray',
          dates: new Date(2022, 11, 27),
          popover: {
            label: '19:00 hct o. Weihnachtsfeier'
          }
        },
        {
          key: 'pokerabend-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 0, 10),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'pokerabend-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 0, 10),
          popover: {
            label: '19:00 hct io. Pokerabend'
          }
        },
        {
          key: 'semesterabschlusskneippe-ws-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 0, 21),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'semesterabschlusskneippe-ws-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 0, 21),
          popover: {
            label: '18:00 hst ho. 3. Convent AC/BC/WC'
          }
        },
        {
          key: 'semesterabschlusskneippe-ws-2023-popup-2',
          dot: 'gray',
          dates: new Date(2023, 0, 21),
          popover: {
            label: '20:00 hct ho. Semesterabschlusskneipe'
          }
        },
        {
          key: 'einholen-fahne-ws-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 0, 22),
          popover: {
            label: 'Ort: Oppeln'
          }
        },
        {
          key: 'einholen-fahne-ws-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 0, 22),
          popover: {
            label: '10:00 hst ho. Heilige Messe in St. Aleksy Kirche'
          }
        },
        {
          key: 'einholen-fahne-ws-2023-popup-2',
          dot: 'gray',
          dates: new Date(2023, 0, 22),
          popover: {
            label: '12:00 hct ho. Einholen der Fahne adH'
          }
        },
        {
          key: 'semesterantrittskneipe-ss-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 2, 11),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'semesterantrittskneipe-ss-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 2, 11),
          popover: {
            label: '18:00 hst ho. 1. Convent AC/BC/DC'
          }
        },
        {
          key: 'semesterantrittskneipe-ss-2023-popup-2',
          dot: 'gray',
          dates: new Date(2023, 2, 11),
          popover: {
            label: '20:00 hct ho. Semesterantrittskneipe'
          }
        },
        {
          key: 'hissen-fahne-ss-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 2, 12),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'hissen-fahne-ss-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 2, 12),
          popover: {
            label: '11:00 hst ho. Heilige Messe in St. Aleksy Kirche'
          }
        },
        {
          key: 'hissen-fahne-ss-2023-popup-2',
          dot: 'gray',
          dates: new Date(2023, 2, 12),
          popover: {
            label: '13:00 hct o. Hissen der Fahne adH'
          }
        },
        {
          key: 'besichtigung-guido-ss-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 2, 25),
          popover: {
            label: 'Ort: Hindenburg'
          }
        },
        {
          key: 'besichtigung-guido-ss-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 2, 25),
          popover: {
            label: 'io. Besichtigung der Kohlegrube "Guido" in Hindenburg'
          }
        },
        {
          key: 'gartentag-convent-ss-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 3, 1),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'gartentag-convent-ss-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 3, 1),
          popover: {
            label: '12:00 hct io. Gartentag'
          }
        },
        {
          key: 'gartentag-convent-ss-2023-popup-2',
          dot: 'gray',
          dates: new Date(2023, 3, 1),
          popover: {
            label: '18:00 hst ho. 2. Convent AC/BC'
          }
        },
        {
          key: 'osterfeuer-ss-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 3, 5),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'osterfeuer-ss-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 3, 5),
          popover: {
            label: '19:00 hct io. Traditionelles Osterfeuer'
          }
        },
        {
          key: 'osterstammtisch-ss-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 3, 11),
          popover: {
            label: 'Ort: Oberglogau'
          }
        },
        {
          key: 'osterstammtisch-ss-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 3, 11),
          popover: {
            label: '19:00 hct o. Traditioneller Osterstammtisch im Gasthaus SALVE'
          }
        },
        {
          key: 'vortrag-wysdak-ss-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 3, 19),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'vortrag-wysdak-ss-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 3, 19),
          popover: {
            label: '18:00 hct o. Vortrag von Bbr. Christoph Wysdak "Korporierte in der polnischer Literatur, Vergleich zweier Texte"'
          }
        },
        {
          key: 'filmabend-ss-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 4, 6),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'filmabend-ss-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 4, 6),
          popover: {
            label: '19:00 hct o. Filmabend'
          }
        },
        {
          key: 'paintball-ss-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 4, 13),
          popover: {
            label: 'Ort: Krascheow'
          }
        },
        {
          key: 'paintball-ss-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 4, 13),
          popover: {
            label: 'io. Paintball bei "Bunker Paintball"'
          }
        },
        {
          key: 'hausputztag-convent-ss-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 4, 27),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'hausputztag-convent-ss-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 4, 27),
          popover: {
            label: '12:00 hct io. Hausputztag'
          }
        },
        {
          key: 'hausputztag-convent-ss-2023-popup-2',
          dot: 'gray',
          dates: new Date(2023, 4, 27),
          popover: {
            label: '18:00 hst ho. 3. Convent AC/BC'
          }
        },
                {
          key: 'hausputztag-convent-ss-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 4, 27),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'hausputztag-convent-ss-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 4, 27),
          popover: {
            label: '12:00 hct io. Hausputztag'
          }
        },
        {
          key: 'hausputztag-convent-ss-2023-popup-2',
          dot: 'gray',
          dates: new Date(2023, 4, 27),
          popover: {
            label: '18:00 hst ho. 3. Convent AC/BC'
          }
        },
        {
          key: 'semesterabschlusskneipe-ss-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 6, 1),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'semesterabschlusskneipe-ss-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 6, 1),
          popover: {
            label: '18:00 hst ho. 4. Convent AC/BC/WC'
          }
        },
        {
          key: 'semesterabschlusskneipe-ss-2023-popup-2',
          dot: 'gray',
          dates: new Date(2023, 6, 1),
          popover: {
            label: '20:00 hct ho. Semesterabschlusskneipe'
          }
        },
        {
          key: 'einholen-fahne-ss-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 6, 2),
          popover: {
            label: 'Ort: Oppeln'
          }
        },
        {
          key: 'einholen-fahne-ss-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 6, 2),
          popover: {
            label: '11:00 hst ho. Heilige Messe in St. Aleksy Kirche'
          }
        },
        {
          key: 'einholen-fahne-ss-2023-popup-2',
          dot: 'gray',
          dates: new Date(2023, 6, 2),
          popover: {
            label: '13:00 hct o. Einholen der Fahne adH'
          }
        },
        {
          key: 'minusbundfest-2023',
          highlight: {
            start: { fillMode: 'solid', color: 'teal' },
            base: { fillMode: 'light', color: 'teal' },
            end: { fillMode: 'solid', color: 'teal' },
          },
          dates: {
            start: new Date(2023, 4, 18),
            end: new Date(2023, 4, 20),
          },
          popover: {
            label: 'io. 20. Stiftungsfest des VDH Oppeln'
          }
        },
        {
          key: 'minusbundfest-2023-day-2',
          highlight: {
            color: 'teal',
            fillMode: 'solid',
          },
          dates: new Date(2023, 4, 19),
        },
        {
          key: 'minusbundfest-2023-day-3',
          highlight: {
            color: 'teal',
            fillMode: 'solid',
          },
          dates: new Date(2023, 4, 20),
        },
        {
          key: 'cartellversammlung-fulda-2023',
          highlight: {
            start: { fillMode: 'solid', color: 'teal' },
            base: { fillMode: 'light', color: 'teal' },
            end: { fillMode: 'solid', color: 'teal' },
          },
          dates: {
            start: new Date(2023, 5, 8),
            end: new Date(2023, 5, 11),
          },
          popover: {
            label: 'o. Cartellversammlung in Fulda'
          }
        },
        {
          key: 'cartellversammlung-fulda-2023-day-2',
          highlight: {
            color: 'teal',
            fillMode: 'solid',
          },
          dates: new Date(2023, 5, 9),
        },
        {
          key: 'cartellversammlung-fulda-2023-day-3',
          highlight: {
            color: 'teal',
            fillMode: 'solid',
          },
          dates: new Date(2023, 5, 10),
        },
        {
          key: 'clausthalfest-2023',
          highlight: {
            start: { fillMode: 'solid', color: 'teal' },
            base: { fillMode: 'light', color: 'teal' },
            end: { fillMode: 'solid', color: 'teal' },
          },
          dates: {
            start: new Date(2023, 5, 16),
            end: new Date(2023, 5, 18),
          },
          popover: {
            label: 'io. 103 Stiftungsfest e.v. A.V. Glückauf-Salia Clausthal-Zellerfeld im CV und KV'
          }
        },
        {
          key: 'clausthalfest-2023-day-2',
          highlight: {
            color: 'teal',
            fillMode: 'solid',
          },
          dates: new Date(2023, 5, 17),
        },
        {
          key: 'clausthalfest-2023-day-3',
          highlight: {
            color: 'teal',
            fillMode: 'solid',
          },
          dates: new Date(2023, 5, 18),
        },
        {
          key: 'freiburgfest-2023',
          highlight: {
            start: { fillMode: 'solid', color: 'teal' },
            base: { fillMode: 'light', color: 'teal' },
            end: { fillMode: 'solid', color: 'teal' },
          },
          dates: {
            start: new Date(2023, 5, 23),
            end: new Date(2023, 5, 25),
          },
          popover: {
            label: 'o. 99. Stiftungsfest unserer Freundschaftsverbindung K.D.St.V. Wildenstein Freiburg im CV'
          }
        },
        {
          key: 'freiburgfest-2023-day-2',
          highlight: {
            color: 'teal',
            fillMode: 'solid',
          },
          dates: new Date(2023, 5, 24),
        },
        {
          key: 'freiburgfest-2023-day-3',
          highlight: {
            color: 'teal',
            fillMode: 'solid',
          },
          dates: new Date(2023, 5, 25),
        },
        {
          key: 'stiftungsfest-2023',
          highlight: {
            start: { fillMode: 'solid', color: 'red' },
            base: { fillMode: 'light', color: 'red' },
            end: { fillMode: 'solid', color: 'red' },
          },
          dates: {
            start: new Date(2023, 5, 2),
            end: new Date(2023, 5, 4),
          },
          popover: {
            label: '31. Stiftungsfest in Groß Stein (Kamień Śląski)'
          }
        },
        {
          key: 'stiftungsfest-2023-day-2',
          highlight: {
            color: 'red',
            fillMode: 'solid',
          },
          dates: new Date(2023, 5, 3),
        },
        {
          key: 'stiftungsfest-2023-day-3',
          highlight: {
            color: 'red',
            fillMode: 'solid',
          },
          dates: new Date(2023, 5, 4),
        },
        {
          key: 'stiftungsfest-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 5, 2),
          popover: {
            label: '19:00 hct o. Begrüßungsabend adH'
          }
        },
        {
          key: 'stiftungsfest-2023-popup-2',
          dot: 'gray',
          dates: new Date(2023, 5, 3),
          popover: {
            label: '10:30 hct io. Damenprogramm - Besichtigung des Schlosses in Moschen (Moszna)'
          }
        },
        {
          key: 'stiftungsfest-2023-popup-3',
          dot: 'gray',
          dates: new Date(2023, 5, 3),
          popover: {
            label: '12:00 hst ho. Sitzung vom Studentenförderverein Silesia e.V.'
          }
        },
        {
          key: 'stiftungsfest-2023-popup-4',
          dot: 'gray',
          dates: new Date(2023, 5, 3),
          popover: {
            label: '14:00 hct io. Mittagessen'
          }
        },
        {
          key: 'stiftungsfest-2023-popup-5',
          dot: 'gray',
          dates: new Date(2023, 5, 3),
          popover: {
            label: '15:00 hct ho. AHC/CC'
          }
        },
        {
          key: 'stiftungsfest-2023-popup-6',
          dot: 'gray',
          dates: new Date(2023, 5, 3),
          popover: {
            label: '19:00 hct io. Abendessen'
          }
        },
        {
          key: 'stiftungsfest-2023-popup-7',
          dot: 'gray',
          dates: new Date(2023, 5, 3),
          popover: {
            label: '20:00 hct io. Festkommers'
          }
        },
        {
          key: 'stiftungsfest-2023-popup-8',
          dot: 'gray',
          dates: new Date(2023, 5, 4),
          popover: {
            label: '10:00 hst ho. Minderheiten Wallfahrt auf dem Sankt Annaberg'
          }
        },
        {
          key: 'schweinschlachten-2023',
          highlight: {
            start: { fillMode: 'solid', color: 'red' },
            base: { fillMode: 'light', color: 'red' },
            end: { fillMode: 'solid', color: 'red' },
          },
          dates: {
            start: new Date(2023, 10, 24),
            end: new Date(2023, 10, 26),
          },
          popover: {
            label: 'Das alljährliche Schweinschlachten in Körnitz (Kórnica)'
          }
        },
        {
          key: 'schweinschlachten-2023-day-2',
          highlight: {
            color: 'red',
            fillMode: 'solid',
          },
          dates: new Date(2023, 10, 25),
        },
        {
          key: 'schweinschlachten-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 10, 24),
          popover: {
            label: '19:00 hct io. Begrüßungsabend adH'
          }
        },
        {
          key: 'schweinschlachten-2023-popup-2',
          dot: 'gray',
          dates: new Date(2023, 10, 25),
          popover: {
            label: '11:00 hct io. Eventschlachten bei Familie Rybczyk'
          },
        },
        {
          key: 'schweinschlachten-2023-popup-3',
          dot: 'gray',
          dates: new Date(2023, 10, 25),
          popover: {
            label: '16:30 hct ho. Cumulativ Convent'
          },
        },
        {
          key: 'schweinschlachten-2023-popup-4',
          dot: 'gray',
          dates: new Date(2023, 10, 25),
          popover: {
            label: '20:30 hct ho. Feierliche Andreaskneipe'
          },
        },
        {
          key: 'schweinschlachten-2023-popup-5',
          dot: 'gray',
          dates: new Date(2023, 10, 26),
          popover: {
            label: '11:00 hst ho. Heilige Messe in St. Aleksy Kirche'
          },
        },
        {
          key: 'semesterantrittskneipe-ws-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 9, 7),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'semesterantrittskneipe-ws-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 9, 7),
          popover: {
            label: '18:00 hst ho. 1. Convent AC/BC/DC'
          }
        },
        {
          key: 'semesterantrittskneipe-ws-2023-popup-2',
          dot: 'gray',
          dates: new Date(2023, 9, 7),
          popover: {
            label: '20:00 hct ho. Semesterantrittskneipe'
          }
        },
        {
          key: 'hissen-fahne-ws-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 9, 8),
          popover: {
            label: 'Ort: Oppeln'
          }
        },
        {
          key: 'hissen-fahne-ws-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 9, 8),
          popover: {
            label: '11:00 hst ho. Heilige Messe in St. Aleksy Kirche'
          }
        },
        {
          key: 'hissen-fahne-ws-2023-popup-2',
          dot: 'gray',
          dates: new Date(2023, 9, 8),
          popover: {
            label: '13:00 hct ho. Hissen der Fahne adH'
          }
        },
        {
          key: 'vortrag-tluczykont-ws-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 9, 21),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'vortrag-tluczykont-ws-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 9, 21),
          popover: {
            label: '19:00 hct io. Vortrag von Bbr. Peter Tluczykont "Fettreich essen macht gesund?! Diätanalyse von Dr. Kwasniewski"'
          }
        },
        {
          key: 'trauerkneipe-ws-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 10, 4),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'trauerkneipe-ws-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 10, 4),
          popover: {
            label: '20:00 hct ho. Trauerkneipe"'
          }
        },
        {
          key: 'convent-2-ws-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 10, 11),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'convent-2-ws-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 10, 11),
          popover: {
            label: '12:00 hct io. Hausputztag'
          }
        },
        {
          key: 'convent-2-ws-2023-popup-2',
          dot: 'gray',
          dates: new Date(2023, 10, 11),
          popover: {
            label: '18:00 hst ho. 2. Convent AC/BC'
          }
        },
        {
          key: 'weihnachtsmarkt-ws-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 11, 2),
          popover: {
            label: 'Ort: Breslau'
          }
        },
        {
          key: 'weihnachtsmarkt-ws-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 11, 2),
          popover: {
            label: '13:00 hct io. Museumbesichtigung der Breslauer Universität'
          }
        },
        {
          key: 'weihnachtsmarkt-ws-2023-popup-2',
          dot: 'gray',
          dates: new Date(2023, 11, 2),
          popover: {
            label: '16:00 hct io. Ausklang am Weihnachtsmarkt'
          }
        },
        {
          key: 'adventskonzert-ws-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 11, 11),
          popover: {
            label: 'Ort: Oppeln'
          }
        },
        {
          key: 'adventskonzert-ws-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 11, 11),
          popover: {
            label: '18:00 hst o. Adventskonzert in der Oppelner Philharmonie'
          }
        },
        {
          key: 'weihnachtsfeier-ws-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2023, 11, 27),
          popover: {
            label: 'Ort: Groß Stein'
          }
        },
        {
          key: 'weihnachtsfeier-ws-2023-popup-1',
          dot: 'gray',
          dates: new Date(2023, 11, 27),
          popover: {
            label: '19:00 hct ho. Weihnachtsfeier bei unserem Verbindungsseelsorger Bbr. Arnold Nowak'
          }
        },
        {
          key: 'pokerabend-ws-2023',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 0, 6),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'pokerabend-ws-2023-popup-1',
          dot: 'gray',
          dates: new Date(2024, 0, 6),
          popover: {
            label: '19:00 hct io. Pokerabend'
          }
        },
        {
          key: 'semesterabschlusskneipe-ws-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 0, 20),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'semesterabschlusskneipe-ws-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 0, 20),
          popover: {
            label: '18:00 hst ho. 3. Convent AC/BC/WC'
          }
        },
        {
          key: 'semesterabschlusskneipe-ws-2024-popup-2',
          dot: 'gray',
          dates: new Date(2024, 0, 20),
          popover: {
            label: '20:00 hct ho. Semesterabschlusskneipe'
          }
        },
        {
          key: 'einholen-fahne-ws-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 0, 21),
          popover: {
            label: 'Ort: Oppeln'
          }
        },
        {
          key: 'einholen-fahne-ws-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 0, 21),
          popover: {
            label: '11:00 hst ho. Heilige Messe in St. Aleksy Kirche'
          }
        },
        {
          key: 'einholen-fahne-ws-2024-popup-2',
          dot: 'gray',
          dates: new Date(2024, 0, 21),
          popover: {
            label: '13:00 hct ho. Einholen der Fahne adH'
          }
        },
        {
          key: 'semesterantrittskneipe-ss-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 2, 2),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'semesterantrittskneipe-ss-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 2, 2),
          popover: {
            label: '18:00 hst ho. 1. Convent AC/BC/DC'
          }
        },
        {
          key: 'semesterantrittskneipe-ss-2024-popup-2',
          dot: 'gray',
          dates: new Date(2024, 2, 2),
          popover: {
            label: '20:00 hct ho. Semesterantrittskneipe'
          }
        },
        {
          key: 'hissen-fahne-ss-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 2, 3),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'hissen-fahne-ss-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 2, 3),
          popover: {
            label: '11:00 hst ho. Heilige Messe in St. Aleksy Kirche'
          }
        },
        {
          key: 'hissen-fahne-ss-2024-popup-2',
          dot: 'gray',
          dates: new Date(2024, 2, 3),
          popover: {
            label: '13:00 hct o. Hissen der Fahne adH'
          }
        },
        {
          key: 'vortrag-lernraum-ss-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 2, 16),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'vortrag-lernraum-ss-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 2, 16),
          popover: {
            label: '18:00 hct o. Vortrag in Zusammenarbeit mit LernRAUM.pl'
          }
        },
        {
          key: 'gartentag-ss-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 2, 23),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'gartentag-ss-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 2, 23),
          popover: {
            label: '12:00 hct io. Gartentag'
          }
        },
        {
          key: 'gartentag-ss-2024-popup-2',
          dot: 'gray',
          dates: new Date(2024, 2, 23),
          popover: {
            label: '18:00 hst ho. 2. Convent AC/BC'
          }
        },
        {
          key: 'osterfeuer-ss-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 2, 27),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'osterfeuer-ss-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 2, 27),
          popover: {
            label: '19:00 hct io. Traditionelles Osterfeuer'
          }
        },
        {
          key: 'osterstammtisch-ss-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 3, 2),
          popover: {
            label: 'Ort: Oberglogau'
          }
        },
        {
          key: 'osterstammtisch-ss-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 3, 2),
          popover: {
            label: '19:00 hct o. Traditioneller Osterstammtisch im Gasthaus SALVE'
          }
        },
        {
          key: 'fuxenkreuzkneipe-mit-minusbund-ss-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 3, 13),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'fuxenkreuzkneipe-mit-minusbund-ss-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 3, 13),
          popover: {
            label: '20:00 hct o. Fuxenkreuzkneipe mit dem VDH Oppeln'
          }
        },
        {
          key: 'hausputztag-3-convent-ss-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 4, 18),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'hausputztag-3-convent-ss-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 4, 18),
          popover: {
            label: '12:00 hct io. Hausputztag'
          }
        },
        {
          key: 'hausputztag-3-convent-ss-2024-popup-2',
          dot: 'gray',
          dates: new Date(2024, 4, 18),
          popover: {
            label: '18:00 hst ho. 3. Convent AC/BC'
          }
        },
        {
          key: 'minderheitenwallfahrt-ss-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 5, 2),
          popover: {
            label: 'Ort: Sankt Annaberg'
          }
        },
        {
          key: 'minderheitenwallfahrt-ss-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 5, 2),
          popover: {
            label: '10:00 hct ho. Minderheitenwallfahrt'
          }
        },
        {
          key: 'semesterabschlusskneipe-ss-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 5, 29),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'semesterabschlusskneipe-ss-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 5, 29),
          popover: {
            label: '18:00 hst ho. 4. Convent AC/BC/WC'
          }
        },
        {
          key: 'semesterabschlusskneipe-ss-2024-popup-2',
          dot: 'gray',
          dates: new Date(2024, 5, 29),
          popover: {
            label: '20:00 hct ho. Semesterabschlusskneipe'
          }
        },
        {
          key: 'einholen-fahne-ss-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 5, 30),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'einholen-fahne-ss-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 5, 30),
          popover: {
            label: '11:00 hst ho. Heilige Messe in St. Aleksy Kirche'
          }
        },
        {
          key: 'einholen-fahne-ss-2024-popup-2',
          dot: 'gray',
          dates: new Date(2024, 5, 30),
          popover: {
            label: '13:00 hct ho. Einholen der Fahne adH'
          }
        },
        {
          key: 'stiftungsfest-2024',
          highlight: {
            start: { fillMode: 'solid', color: 'red' },
            base: { fillMode: 'light', color: 'red' },
            end: { fillMode: 'solid', color: 'red' },
          },
          dates: {
            start: new Date(2024, 4, 24),
            end: new Date(2024, 4, 26),
          },
          popover: {
            label: '32. Stiftungsfest in Groß Stein (Kamień Śląski)'
          }
        },
        {
          key: 'stiftungsfest-2024-day-2',
          highlight: {
            color: 'red',
            fillMode: 'solid',
          },
          dates: new Date(2024, 4, 25),
        },
        {
          key: 'stiftungsfest-2024-day-3',
          highlight: {
            color: 'red',
            fillMode: 'solid',
          },
          dates: new Date(2024, 4, 26),
        },
        {
          key: 'stiftungsfest-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 4, 24),
          popover: {
            label: '19:00 hct o. Begrüßungsabend adH'
          }
        },
        {
          key: 'stiftungsfest-2024-popup-2',
          dot: 'gray',
          dates: new Date(2024, 4, 25),
          popover: {
            label: '12:00 hct io. Damenprogramm - Besichtigung des Schlosses in Groß Stein'
          }
        },
        {
          key: 'stiftungsfest-2024-popup-3',
          dot: 'gray',
          dates: new Date(2024, 4, 25),
          popover: {
            label: '12:00 hst ho. Sitzung vom Studentenförderverein Silesia e.V.'
          }
        },
        {
          key: 'stiftungsfest-2024-popup-4',
          dot: 'gray',
          dates: new Date(2024, 4, 25),
          popover: {
            label: '14:00 hct io. Mittagessen'
          }
        },
        {
          key: 'stiftungsfest-2024-popup-5',
          dot: 'gray',
          dates: new Date(2024, 4, 25),
          popover: {
            label: '15:00 hct ho. AHC/CC'
          }
        },
        {
          key: 'stiftungsfest-2024-popup-6',
          dot: 'gray',
          dates: new Date(2024, 4, 25),
          popover: {
            label: '19:00 hct io. Abendessen'
          }
        },
        {
          key: 'stiftungsfest-2024-popup-7',
          dot: 'gray',
          dates: new Date(2024, 4, 25),
          popover: {
            label: '20:00 hst ho. Festkommers'
          }
        },
        {
          key: 'stiftungsfest-2024-popup-8',
          dot: 'gray',
          dates: new Date(2024, 4, 26),
          popover: {
            label: '11:00 hst ho. Heilige Messe in St. Aleksy Kirche in Oppeln-Zentrum'
          }
        },
        {
          key: 'stiftungsfest-2024-popup-9',
          dot: 'gray',
          dates: new Date(2024, 4, 26),
          popover: {
            label: '13:00 hct o. Frühschoppen adH'
          }
        },
        {
          key: 'freiburgfest-2024',
          highlight: {
            start: { fillMode: 'solid', color: 'teal' },
            base: { fillMode: 'light', color: 'teal' },
            end: { fillMode: 'solid', color: 'teal' },
          },
          dates: {
            start: new Date(2024, 4, 8),
            end: new Date(2024, 4, 12),
          },
          popover: {
            label: 'ho. 100. Stiftungsfest unserer Freundschaftsverbindung K.D.St.V. Wildenstein Freiburg im CV'
          }
        },
        {
          key: 'freiburgfest-2024-day-2',
          highlight: {
            color: 'teal',
            fillMode: 'solid',
          },
          dates: new Date(2024, 4, 9),
        },
        {
          key: 'freiburgfest-2024-day-3',
          highlight: {
            color: 'teal',
            fillMode: 'solid',
          },
          dates: new Date(2024, 4, 10),
        },
        {
          key: 'freiburgfest-2024-day-4',
          highlight: {
            color: 'teal',
            fillMode: 'solid',
          },
          dates: new Date(2024, 4, 11),
        },
        {
          key: 'cartellversammlung-berlin-2024',
          highlight: {
            start: { fillMode: 'solid', color: 'teal' },
            base: { fillMode: 'light', color: 'teal' },
            end: { fillMode: 'solid', color: 'teal' },
          },
          dates: {
            start: new Date(2024, 4, 30),
            end: new Date(2024, 5, 2),
          },
          popover: {
            label: 'ho. Cartellversammlung in Berlin'
          }
        },
        {
          key: 'cartellversammlung-berlin-2024-day-2',
          highlight: {
            color: 'teal',
            fillMode: 'solid',
          },
          dates: new Date(2024, 4, 31),
        },
        {
          key: 'cartellversammlung-berlin-2024-day-3',
          highlight: {
            color: 'teal',
            fillMode: 'solid',
          },
          dates: new Date(2024, 5, 1),
        },
        {
          key: 'schweinschlachten-2024',
          highlight: {
            start: { fillMode: 'solid', color: 'red' },
            base: { fillMode: 'light', color: 'red' },
            end: { fillMode: 'solid', color: 'red' },
          },
          dates: {
            start: new Date(2024, 10, 22),
            end: new Date(2024, 10, 24),
          },
          popover: {
            label: 'Das alljährliche Schweinschlachten in Körnitz (Kórnica)'
          }
        },
        {
          key: 'schweinschlachten-2024-day-2',
          highlight: {
            color: 'red',
            fillMode: 'solid',
          },
          dates: new Date(2024, 10, 23),
        },
        {
          key: 'schweinschlachten-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 10, 22),
          popover: {
            label: '19:00 hct io. Begrüßungsabend adH'
          }
        },
        {
          key: 'schweinschlachten-2024-popup-2',
          dot: 'gray',
          dates: new Date(2024, 10, 23),
          popover: {
            label: '11:00 hct io. Eventschlachten bei Familie Rybczyk'
          },
        },
        {
          key: 'schweinschlachten-2024-popup-3',
          dot: 'gray',
          dates: new Date(2024, 10, 23),
          popover: {
            label: '16:30 hct ho. Cumulativ Convent'
          },
        },
        {
          key: 'schweinschlachten-2024-popup-4',
          dot: 'gray',
          dates: new Date(2024, 10, 23),
          popover: {
            label: '20:30 hct ho. Feierliche Andreaskneipe'
          },
        },
        {
          key: 'schweinschlachten-2024-popup-5',
          dot: 'gray',
          dates: new Date(2024, 10, 24),
          popover: {
            label: '11:00 hst ho. Heilige Messe in St. Aleksius Kirche'
          },
        },
        {
          key: 'schweinschlachten-2024-popup-6',
          dot: 'gray',
          dates: new Date(2024, 10, 24),
          popover: {
            label: '13:00 hst o. Ausklang adH'
          },
        },
        {
          key: 'semesterantrittskneipe-ws-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 9, 5),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'semesterantrittskneipe-ws-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 9, 5),
          popover: {
            label: '18:00 hst ho. 1. Convent AC/BC/DC'
          }
        },
        {
          key: 'semesterantrittskneipe-ws-2024-popup-2',
          dot: 'gray',
          dates: new Date(2024, 9, 5),
          popover: {
            label: '20:00 hct ho. Semesterantrittskneipe'
          }
        },
        {
          key: 'hissen-fahne-ws-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 9, 6),
          popover: {
            label: 'Ort: Oppeln'
          }
        },
        {
          key: 'hissen-fahne-ws-2023-popup-1',
          dot: 'gray',
          dates: new Date(2024, 9, 6),
          popover: {
            label: '11:00 hst ho. Heilige Messe in St. Aleksy Kirche'
          }
        },
        {
          key: 'hissen-fahne-ws-2023-popup-2',
          dot: 'gray',
          dates: new Date(2024, 9, 6),
          popover: {
            label: '13:00 hct ho. Hissen der Fahne adH'
          }
        },
        {
          key: 'armbrustschiessubungen-ws-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 9, 12),
          popover: {
            label: 'Ort: Komornik'
          }
        },
        {
          key: 'armbrustschiessubungen-ws-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 9, 12),
          popover: {
            label: '16:00 hct io. Armbrustschießübungen beim dem hohen FM Bbr Michael Wollny'
          }
        },
        {
          key: 'vortrag-lernraum-ws-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 10, 9),
          popover: {
            label: 'Ort: Körnitz'
          }
        },
        {
          key: 'vortrag-lernraum-ws-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 10, 9),
          popover: {
            label: '18:00 hct o. Vortrag in Zusammenarbeit mit LernRAUM.pl Leiter: Phil-x Bbr Peter Rybczyk „Die Verwendung von Fetten - Elemente der Chemie im täglichen Leben."'
          }
        },
        {
          key: 'vortrag-linek-ws-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 9, 26),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'vortrag-linek-ws-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 9, 26),
          popover: {
            label: '17:00 hct o. Vortrag von Dr. Bernard Linek „Die deutsche Frage in Oberschlesien in der Nachkriegszeit. (1945-1960)“'
          }
        },
        {
          key: 'convent-2-ws-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 10, 16),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'convent-2-ws-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 10, 16),
          popover: {
            label: '12:00 hct io. Hausputztag'
          }
        },
        {
          key: 'convent-2-ws-2024-popup-2',
          dot: 'gray',
          dates: new Date(2024, 10, 16),
          popover: {
            label: '18:00 hst ho. 2. Convent AC/BC'
          }
        },
        {
          key: 'bergmannstag-ws-2024',
          highlight: {
            start: { fillMode: 'solid', color: 'red' },
            base: { fillMode: 'light', color: 'red' },
            end: { fillMode: 'solid', color: 'red' },
          },
          dates: {
            start: new Date(2024, 10, 29),
            end: new Date(2024, 10, 30),
          },
          popover: {
            label: 'Bergmannstage und Ledersprung an der TU Gleiwitz'
          }
        },
        {
          key: 'aktivenfahrt-trier-ws-2024',
          highlight: {
            start: { fillMode: 'solid', color: 'red' },
            base: { fillMode: 'light', color: 'red' },
            end: { fillMode: 'solid', color: 'red' },
          },
          dates: {
            start: new Date(2024, 11, 5),
            end: new Date(2024, 11, 8),
          },
          popover: {
            label: 'Aktivenfahrt nach Trier zu e.v. KDStV Churtrier Trier im CV'
          }
        },
        {
          key: 'weihnachtsmarkt-ws-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 11, 21),
          popover: {
            label: 'Ort: Breslau'
          }
        },
        {
          key: 'weihnachtsmarkt-ws-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 11, 21),
          popover: {
            label: '13:00 hct io. Museumbesichtigung der Breslauer Universität'
          }
        },
        {
          key: 'weihnachtsmarkt-ws-2024-popup-2',
          dot: 'gray',
          dates: new Date(2024, 11, 21),
          popover: {
            label: '16:00 hct io. Ausklang am Weihnachtsmarkt'
          }
        },
        {
          key: 'weihnachtsfeier-ws-2024',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2024, 11, 27),
          popover: {
            label: 'Ort: Groß Stein'
          }
        },
        {
          key: 'weihnachtsfeier-ws-2024-popup-1',
          dot: 'gray',
          dates: new Date(2024, 11, 27),
          popover: {
            label: '19:00 hct ho. Weihnachtsfeier bei unserem Verbindungsseelsorger Bbr. Arnold Nowak'
          }
        },
        {
          key: 'pokerabend-ws-2025',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2025, 0, 11),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'pokerabend-ws-2025-popup-1',
          dot: 'gray',
          dates: new Date(2025, 0, 11),
          popover: {
            label: '19:00 hct io. Pokerabend mit Whisky und Cigarren'
          }
        },
        {
          key: 'semesterabschlusskneipe-ws-2025',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2025, 0, 25),
          popover: {
            label: 'Ort: Oppeln adH'
          }
        },
        {
          key: 'semesterabschlusskneipe-ws-2025-popup-1',
          dot: 'gray',
          dates: new Date(2025, 0, 25),
          popover: {
            label: '18:00 hst ho. 3. Convent AC/BC/WC'
          }
        },
        {
          key: 'semesterabschlusskneipe-ws-2025-popup-2',
          dot: 'gray',
          dates: new Date(2025, 0, 25),
          popover: {
            label: '20:00 hct ho. Semesterabschlusskneipe'
          }
        },
        {
          key: 'einholen-fahne-ws-2025',
          highlight: {
            color: 'orange',
            fillMode: 'solid',
          },
          dates: new Date(2025, 0, 26),
          popover: {
            label: 'Ort: Oppeln'
          }
        },
        {
          key: 'einholen-fahne-ws-2025-popup-1',
          dot: 'gray',
          dates: new Date(2025, 0, 26),
          popover: {
            label: '11:00 hst ho. Heilige Messe in St. Aleksy Kirche'
          }
        },
        {
          key: 'einholen-fahne-ws-2025-popup-2',
          dot: 'gray',
          dates: new Date(2025, 0, 26),
          popover: {
            label: '13:00 hct ho. Einholen der Fahne adH'
          }
        },
      ];
    }, 
  },
};
</script>

<style lang="scss" scoped>
div.program {
  padding-bottom: 0;
  margin-bottom: 0;

  .calendar {
    padding: 32px 32px;
  }
}

.program-content-wrapper {
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
  background-color: #303030;
  color: #f1f1f1;

  @media(min-width: 700px) {
      min-height: 450px;
      max-height: 650px;
      flex-direction: row;
  }

  
  .band-navbar {
    width: 100%;
    height: 15px;
    left: 0;
    position: absolute;
  }

  .program-content {
    display: flex;
    justify-content: center;
    text-align: justify;
    align-items: center;
  }

  div {
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 72px 24px;

    @media(min-width: 700px) {
        padding: 0 24px;
    }

    img {
      margin: 36px;
    }

    h2 {
        margin: 12px;
        font-size: 32px;
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: 24px;
        @media(min-width: 700px) {
            font-size: 40px;
        }
    }

    p {
        font-weight: 300;
        line-height: 1.7;
    }

    .program-data {
        font-size: 18px;
        text-align: center;
        margin-bottom: 48px;
        white-space: pre-line;
    }    
  }
}
</style>